<template>
    <div class="page">
        <app-header :navIndex="1"></app-header>
        <banner :data="banner"></banner>
        <div class="top-box section-container pt-md-10">
            <div class="top-border hidden-md-and-up white"></div>
            <div class="pb-6 pt-2 pt-md-0">
                <v-hover class="mt-5 ma-md-6" v-for="(item,index) in newsList" :key="index"
                         v-slot:default="{ hover }">
                    <v-card class="d-flex flex-row justify-space-between align-center"
                            :elevation="hover ? 12 : 0"
                            :class="{ 'on-hover': hover }"
                            :to="{path:'/company/newsDetails',query:{id:item.id}}">
                        <div class="col-md-6 hidden-sm-and-down pa-0" :class="index%2==0?'order-first':'order-2'">
                            <v-img contain :src="$utils.formatImgUrl(item.imgUrl)"></v-img>
                        </div>

                        <div class="inner col-md-6 order-1 pl-md-12 pr-md-12">
                            <div class="title pl-5 pr-5 pl-md-0 pr-md-0">{{item.title}}</div>
                            <div class="time black--text ml-5 mr-5 ml-md-0 mr-md-0 pb-2 mt-2 pt-2 pt-md-0">
                                {{item.pubTime}}
                            </div>
                            <div class="hidden-md-and-up col-sm-12">
                                <v-img :src="$utils.formatImgUrl(item.imgUrl)"></v-img>
                            </div>
                            <div class="desc mt-2 pl-5 pr-5 pl-md-0 pr-md-0" v-html="item.subTitle"></div>
                        </div>
                    </v-card>
                </v-hover>
            </div>
            <div class="bottom-tip-box">
                <!--<div class="bottom-tip" v-if="!nextPage">已经到底啦</div>-->
                <div class="bottom-tip bottom-btn" v-if="nextPage" @click="initData">{{$i18n.t('common.moreBtn')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import Banner from "../../components/base/banner";
    import AppHeader from "../../components/core/header/index";
    export default {
        components: {
            AppHeader,
            Banner
        },
        data() {
            return {
                banner: {
                    imgUrl: 'company/news/bg.jpg',
                    title: this.$i18n.t('others.news.title'),
                    enTitle: '',
                },
                typeList: [{id: 1, title: '全部'}, {id: 2, title: '企业新闻'}, {id: 3, title: '品牌资讯'}],
                newsList: this.$i18n.t('others.news.list'),
                activeIndex: 1,
                pageNo: 0,
                pageSize: 10,
                nextPage: true
            }
        },
        created(){
            this.initData()
        },
        mounted(){
            if(document.getElementById('h1Id')) {
                document.getElementById("bodyId").removeChild(document.getElementById('h1Id'))
            }
            let div = document.createElement('h1')
            div.style = "opacity: 0"
            div.id = "h1Id"
            div.innerHTML = "新闻资讯"
            document.getElementById("bodyId").appendChild(div);
        },
        methods: {
            initData(){
                this.$api.web.getNews({pageNo: this.pageNo, pageSize: this.pageSize}).then(res => {
                    this.newsList = res.pagination.currentPage == 0 ? (res.rows || []) : this.newsList.concat(res.rows || []);
                    this.nextPage = res.pagination.nextPage
                    this.pageNo = res.pagination.currentPage + 1
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .type {
        font-size: 26px;
        color: $primary-color;
        background-color: transparent;
        cursor: pointer;
    }

    .selected-type {
        color: white;
        background-color: $primary-color;
        border-radius: 10px;
    }

    .inner {
        .title {
            color: $primary-color;
            font-size: 28px;
        }
        .time {
            font-size: 30px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.20);
        }
    }

    .bottom-tip-box {
        text-align: center;
        .bottom-tip {
            font-size: 18px;
            padding: 30px;
        }
        .bottom-btn {
            cursor: pointer;
        }
    }

    @media (max-width: $screen-md) {
        .type {
            font-size: 14px;
            color: #999999;
            background-color: transparent;
        }
        .selected-type {
            color: $primary-color;
            border-bottom: 1px solid $primary-color;
            background-color: transparent;
            border-radius: 0;
        }
        .inner {
            width: 100%;
            .title, .time {
                font-size: 20px !important;
            }
            .time {
                border-top: 1px solid rgba(0, 0, 0, 0.20);
                border-bottom: 0;
            }
        }
        .bottom-tip-box {
            .bottom-tip {
                font-size: 10px;
                padding: 20px;
            }
        }
    }

    @media (max-width: $screen-sm) {
        .inner {
            width: 100%;
            .title, .time {
                font-size: 16px !important;
            }
        }
    }
</style>
